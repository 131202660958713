import { MortgageType } from "..";

export interface ComplianceTestResult {
    result: TestResult
    type: string
    displayName: string
    errorMessages: string[]
    resultText: string
    testResults: ComplianceSuiteTestResult[]
}

export interface ComplianceSuiteTestResult {
    result: TestResult
    type: string
    displayName: string
    resultText: string
    subTestErrors: string[]
}

export enum TestResult {
    NotApplicable = 'NotApplicable',
    Warning = 'Warning',
    Fail = 'Fail',
    Pass = 'Pass',
    DataNeeded = 'DataNeeded'
}

export interface ComplianceOnlyData {
    isSeasonalLoan: boolean
    isTimeshare: boolean
    isBond: boolean
    isTarpLoan: boolean
    isReverse: boolean
    isLoanQmExempt: boolean
    hasNegativeAmortization: boolean
    propertyInspection: string
    advancesMadeAsConstructionProgresses: boolean
    loanEstimateInitialDeliveryDate: Date
    loanEstimateRevisedDeliveryDate: Date
    loanEstimateInitialMailedDate: Date
    loanEstimateRevisedMailedDate: Date
    closingDisclosureDeliveryDate: Date
    closingDisclosureMailedDate: Date
    respaCounselingDisclosureDate: Date
    intentToProceedDate: Date
    settlementServiceProviderDeliveryDate: Date
    settlementServiceProviderMailedDate: Date
    hoepaDisclosureDate: Date
    hoepaCounselingDate: Date
    helocDisclosureDate: Date
    rightToCopyOfAppraisalDisclosureDate: Date
    rightToCopyWaived: boolean
    appraisalDeliveries: AppraisalDelivery[]
    usdaPriorLoanMortgageType: MortgageType
    usdaPriorLoanNoteRate: number
    licenseeName: string
    cureForTotalOfPayments: number
    tridToleranceReimbursementDate: Date
    cureForTexasA6Fees: number
}

export interface AppraisalDelivery {
    documentType: string
    issuedDate: Date
    receivedDate: Date
}

export interface ComplianceApiResult{
    results: ComplianceTestResult[]
    pdf: string
}
