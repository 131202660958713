export interface PreparedBy {
    attorney: string
    company: string
    signatureImageName: string
    phone: string
    states: string[]
    address: PreparedByAddress
    barNumber: string
    type: PreparedByType
    individualType: PreparedByIndividualType
    individualName: string
}

export interface PreparedByAddress {
    street: string
    city: string
    state: string
    zip: string
}

export enum PreparedByType {
    Attorney = 'Attorney',
    LoanLender = 'LoanLender'
}

export enum PreparedByIndividualType {
    LoanCloser = 'LoanCloser',
    Other = 'Other'
}