import { regCheckPartnerRoutes } from '@/regcheck/router/admin'
import { regCheckClientRoutes } from '@/regcheck/router/admin'
export default [
    {
        path: '/admin',
        component: () => import('@/common/views/admin/admin-area-layout.vue'),
        children: [
            {
                path: 'partners',
                name: 'partner-grid',
                component: () => import('@/common/views/admin/partners/index.vue'),
                meta: {
                    title: '',
                    readPermission: 'admindata.partners:read',
                    writePermission: 'admindata.partners:write'
                }
            },   
            {
                path: 'partners/:code',
                component: () => import('@/common/views/admin/partners/partner.vue'),
                meta: {
                    requiresPartnerSideNav: true
                },
                children: [
                    {
                        path: '',
                        name: 'partner-config',
                        component: () => import('@/common/views/admin/partners/config.vue'),
                        meta: {
                            title: 'Configuration',
                            itemInfo: true,
                            getter: 'partner',
                            readPermission: 'admindata.partners.config:read',
                            writePermission: 'admindata.partners.config:write'
                        }
                    },
                    {
                        path: 'interface',
                        name: 'partner-interface',
                        component: () => import('@/common/views/admin/partners/data-interface.vue'),
                        meta: {
                            title: 'Data Interface',
                            itemInfo: true,
                            getter: 'partner',
                            readPermission: 'admindata.partners.config:read',
                            writePermission: 'admindata.partners.config:write'
                        } 
                    },
                    {
                        path: 'deed-of-trust',
                        name: 'partner-deed-of-trust',
                        component: () => import('@/common/views/admin/partners/deed-of-trust.vue'),
                        meta: {
                            title: 'Deed of Trust',
                            itemInfo: true,
                            getter: 'partner',
                            readPermission: 'admindata.partners.settings:read',
                            writePermission: 'admindata.partners.settings:write'
                        } 
                    },
                    {
                        path: 'doc-prep-attorney',
                        name: 'partner-doc-prep-attorney',
                        component: () => import('@/common/views/admin/partners/doc-prep-attorney.vue'),
                        meta: {
                            title: 'Doc Prep/Attorney',
                            itemInfo: true,
                            getter: 'partner',
                            readPermission: 'admindata.partners.settings:read',
                            writePermission: 'admindata.partners.settings:write'
                        }
                    },
                    {
                        path: 'prepared-by',
                        name: 'partner-prepared-by',
                        component: () => import('@/common/views/admin/partners/prepared-by.vue'),
                        meta: {
                            title: 'Prepared By',
                            itemInfo: true,
                            getter: 'partner',
                            readPermission: 'admindata.partners.settings:read',
                            writePermission: 'admindata.partners.settings:write'
                        }
                    },
                    {
                        path: 'closing-conditions',
                        name: 'partner-closing-conditions',
                        component: () => import('@/common/views/admin/partners/partner-closing-conditions.vue'),
                        meta: {
                            title: 'Closing Conditions',
                            itemInfo: true,
                            getter: 'partner',
                            readPermission: 'admindata.partners.settings:read',
                            writePermission: 'admindata.partners.settings:write'
                        }
                    },
                    {
                        path: 'partner-loan-data',
                        name: 'partner-loan-data',
                        component: () => import('@/common/views/admin/partners/loan-data.vue'),
                        meta: {
                            title: 'Loan Data',
                            itemInfo: true,
                            getter: 'partner',
                            readPermission: 'admindata.partners.settings:read',
                            writePermission: 'admindata.partners.settings:write'
                        }
                    },
                    {
                        path: 'package-settings',
                        name: 'partner-package-settings',
                        component: () => import('@/common/views/admin/partners/package-sorting.vue'),
                        meta: {
                            title: 'Package Settings',
                            itemInfo: true,
                            getter: 'partner',
                            readPermission: 'admindata.partners.config:read',
                            writePermission: 'admindata.partners.config:write'
                        }
                    },
                    ...regCheckPartnerRoutes
                ]
            },
            {
                path: 'lenders',
                name: 'lender-grid',
                component: () => import('@/common/views/admin/clients/grid/grid.vue'),
                meta: {
                    title: 'Lenders',
                    readPermission: 'admindata.clients',
                    writePermission: 'admindata.clients:write'
                }
            },             
            {
                path: 'lenders/:code',
                component: () => import('@/common/views/admin/clients/index.vue'),
                meta: {
                    requiresClientSideNav: true
                },
                children: [
                    {
                        path: '',
                        redirect: 'lender-profiles'
                    },
                    {
                        path: 'lender-profiles',
                        name: 'client-lender-profiles',
                        component: () => import('@/common/views/admin/clients/lender-profiles/lender-profiles.vue'),
                        meta: {
                            title: 'Lender Profiles',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.profile',
                            writePermission: 'admindata.clients.profile:write'
                        }
                    },
                    {
                        path: 'config',
                        name: 'account-setup',
                        component: () => import('@/common/views/admin/clients/account-setup.vue'),
                        meta: {
                            title: 'Account Setup',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.config',
                            writePermission: 'admindata.clients.config:write'
                        }
                    },
                    {
                        path: 'basic-setup',
                        name: 'basic-setup',
                        component: () => import('@/common/views/admin/clients/config.vue'),
                        meta: {
                            title: 'Configuration',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.config',
                            writePermission: 'admindata.clients.config:write'
                        }
                    },
                    {
                        path: 'package-settings',
                        name: 'lender-package-settings',
                        component: () => import('@/common/views/admin/clients/packages.vue'),
                        meta: {
                            title: 'Package Settings',
                            readPermission: 'admindata.clients.config',
                            writePermission: 'admindata.clients.config:write'
                        }
                    },
                    {
                        path: "service-providers",
                        name: "lender-service-providers",
                        component: () => import("@/common/views/admin/clients/service-providers/service-providers.vue"),
                        meta: {
                            title: "Service Providers",
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.serviceProviders',
                            writePermission: 'admindata.clients.settings.serviceProviders:write'
                        }
                    },
                    {
                        path: "service-providers/:id",
                        name: "lender-service-provider",
                        component: () => import("@/common/views/admin/clients/service-providers/service-provider-edit.vue"),
                        metadata: {
                            readPermission: 'admindata.clients.serviceProviders',
                            writePermission: 'admindata.clients.settings.serviceProviders:write'
                        }
                    },
                    {
                        path: 'email',
                        name: 'lender-email-delivery',
                        component: () => import('@/common/views/admin/clients/delivery-options/email/email.vue'),
                        meta: {
                            title: 'Email',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.delivery',
                            writePermission: 'admindata.clients.settings.delivery:write'
                        }
                    },
                    {
                        path: 'loan-review',
                        name: 'lender-loan-review',
                        component: () => import('@/common/views/admin/clients/delivery-options/loan-review/loan-review.vue'),
                        meta: {
                            title: 'Loan Review',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.delivery',
                            writePermission: 'admindata.clients.settings.delivery:write'
                        }
                    },
                    {
                        path: 'docusign',
                        name: 'lender-docusign',
                        component: () => import('@/common/views/admin/clients/delivery-options/docusign/docusign.vue'),
                        meta: {
                            title: 'DocuSign',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.delivery',
                            writePermission: 'admindata.clients.settings.delivery:write'
                        }
                    },
                    {
                        path: 'eoriginal',
                        name: 'lender-eoriginal',
                        component: () => import('@/common/views/admin/clients/delivery-options/eoriginal/eoriginal.vue'),
                        meta: {
                            title: 'eOriginal',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.delivery',
                            writePermission: 'admindata.clients.settings.delivery:write'
                        }
                    },
                    {
                        path: 'notarycam',
                        name: 'lender-notarycam',
                        component: () => import('@/common/views/admin/clients/delivery-options/notarycam/notarycam.vue'),
                        meta: {
                            title: 'NotaryCam',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.delivery',
                            writePermission: 'admindata.clients.settings.delivery:write'
                        }
                    },
                    {
                        path: 'lender-data-retention',
                        name: 'lender-data-retention',
                        component: () => import('@/common/views/admin/clients/data-retention.vue'),
                        meta: {
                            title: 'Data Retention',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.config',
                            writePermission: 'admindata.clients.config:write'
                        }
                    },
                    {
                        path: 'data-interface',
                        name: 'lender-data-interface',
                        component: () => import('@/common/views/admin/clients/data-interface.vue'),
                        meta: {
                            title: 'Data Interface',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.config.defaults',
                            writePermission: 'admindata.clients.config.defaults:write'
                        }
                    },
                    {
                        path: 'lender-loan-data',
                        name: 'lender-loan-data',
                        component: () => import('@/common/views/admin/clients/loan-data.vue'),
                        meta: {
                            title: 'Loan Data',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'documents',
                        name: 'lender-documents',
                        component: () => import('@/common/views/admin/clients/documents.vue'),
                        meta: {
                            title: 'Documents',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'barcodes',
                        name: 'lender-barcodes',
                        component: () => import('@/common/views/admin/clients/barcodes/barcodes.vue'),
                        meta: {
                            title: 'Barcodes',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.barcodes:write'
                        }
                    },
                    {
                        path: 'doc-prep-attorney',
                        name: 'lender-doc-prep-attorney',
                        component: () => import('@/common/views/admin/clients/doc-prep-attorney.vue'),
                        meta: {
                            title: 'Doc Prep/Attorney',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'prepared-by',
                        name: 'lender-data-prepared-by',
                        component: () => import('@/common/views/admin/clients/prepared-by.vue'),
                        meta: {
                            title: 'Prepared By',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'lender-calculations',
                        name: 'lender-calculations',
                        component: () => import('@/common/views/admin/clients/client-calculations.vue'),
                        meta: {
                            title: 'Calculations',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'lender-document-defaults',
                        name: 'lender-document-defaults',
                        component: () => import('@/common/views/admin/clients/document-defaults.vue'),
                        meta: {
                            title: 'General Defaults',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'le-cd',
                        name: 'lender-closing-disclosure',
                        component: () => import('@/common/views/admin/clients/closing-disclosure.vue'),
                        meta: {
                            title: 'Calculations',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'contacts',
                        name: 'lender-contacts',
                        component: () => import('@/common/views/admin/clients/contacts/contacts.vue'),
                        meta: {
                            title: 'Contact Library', // named this to sync up with side menu
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.contacts',
                            writePermission: 'admindata.clients.settings.contacts:write'
                        }             
                    },
                    {
                        path: 'contacts/:id',
                        name: 'lender-contact',
                        component: () => import('@/common/views/admin/clients/contacts/contact.vue'),
                        //has no meta title data so that "Contact Library" remains shown when a contact is selected
                        meta: {
                            readPermission: 'admindata.clients.settings.contacts',
                            writePermission: 'admindata.clients.settings.contacts:write'
                        }
                    },
                    {
                        path: 'closing-conditions',
                        name: 'client-closing-conditions',
                        component: () => import('@/common/views/admin/clients/client-closing-conditions.vue'),
                        meta: {
                            title: 'Closing Conditions',
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.closingconditions',
                            writePermission: 'admindata.clients.settings.closingconditions:write'
                        } 
                    },
                    {
                        path: 'lender-investors',
                        name: 'lender-investors',
                        component: () => import('@/common/views/admin/clients/investors/index.vue'),
                        meta: {
                            readPermission: 'admindata.clients.settings.investors',
                            writePermission: 'admindata.clients.settings.investors:write'
                        }
                    },
                    {
                        path: 'lender-investors/:key',
                        name: 'lender-investor-detail',
                        component: () => import('@/common/views/admin/clients/investors/client-investor.vue'),
                        meta: {
                            readPermission: 'admindata.clients.settings.investors',
                            writePermission: 'admindata.clients.settings.investors:write'
                        }
                    },
                    {
                        path: 'deed-of-trust',
                        name: 'lender-deed-of-trust',
                        component: () => import('@/common/views/admin/clients/deed-of-trust.vue'),
                        meta: {
                            title: 'Deed of Trust', 
                            itemInfo: true,
                            getter: 'client',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        } 
                    },
                    {
                        path: '4506-c',
                        name: 'lender-4506-c',
                        component: () => import('@/common/views/admin/clients/4506-c.vue'),
                        meta: {
                            title: '4506-C',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'helocs',
                        name: 'lender-helocs',
                        component: () => import('@/common/views/admin/clients/helocs/helocs.vue'),
                        meta: {
                            title: 'HELOCs',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'state-specific',
                        name: 'lender-state-specific',
                        component: () => import('@/common/views/admin/clients/state-specific.vue'),
                        meta: {
                            title: 'State-Specific',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'lender-warehouse-banks',
                        name: 'lender-warehouse-banks',
                        component: () => import('@/common/views/admin/clients/warehouse-banks/client-warehouse-banks.vue'),
                        meta: {
                            title: 'Warehouse Banks',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'fee-payees',
                        name: 'fee-payees',
                        component: () => import('@/common/views/admin/clients/fee-payees.vue'),
                        meta: {
                            title: 'Fee Payees',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    {
                        path: 'notary-acknowledgments',
                        name: 'notary-acknowledgments',
                        component: () => import('@/common/views/admin/clients/notary-acknowledgments.vue'),
                        meta: {
                            title: 'Notary Acknowledgments',
                            readPermission: 'admindata.clients.settings.defaults',
                            writePermission: 'admindata.clients.settings.defaults:write'
                        }
                    },
                    ...regCheckClientRoutes
                ]
            },
            {
                path: 'users',
                name: 'user-grid',
                component: () => import('@/common/views/admin/users/user-grid.vue'),
                meta: {
                    title: '',
                    readPermission: 'users:read',
                    writePermission: 'users:write'
                }
            },   
            {
                path: 'users/:id',
                component: () => import('@/common/views/admin/users/user.vue'),
                meta: {
                    requiresUserSideNav: true
                },
                children: [
                    {
                        path: '',
                        name: 'user',
                        component: () => import('@/common/views/admin/users/user-details.vue'),
                        meta: {
                            title: 'User Details',
                            itemInfo: true,
                            getter: 'user',
                            readPermission: 'users:read',
                            writePermission: 'users:write'
                        }
                    }
                ]
            }
        ] 
     }
]
