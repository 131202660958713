export interface DocPrepAttorney {
    name: string
    attn: string
    signatureImageName: string
    phone: string
    phoneExt: string
    address: DocPrepAttorneyAddress
    states: string[]
    type: DocPrepAttorneyType
}

export interface DocPrepAttorneyAddress {
    street: string
    unit: string
    city: string
    state: string
    zip: string
}

export enum DocPrepAttorneyType {
    AttorneyOrIndividual = 'AttorneyOrIndividual',
    LoanLender = 'LoanLender'
}