import { RegCheckSuiteOptions } from './regcheck-options';
import { AdminTrustees } from './deed-of-trust'
import { DocPrepAttorney } from './doc-prep'
import { PreparedBy } from './prepared-by'
import { AuditInfo, Products, RuleBasedClosingCondition } from '@/common/models'

export interface Partner {
    code: string
    name: string
    useSystemTrustees: boolean
    trustees: AdminTrustees[]
    docPrepAttorneys: DocPrepAttorney[]
    useSystemDocPrepAttorneys: boolean
    overwriteDocPrepAttorneysImportData: boolean
    preparedBy: PreparedBy[]
    useSystemPreparedBys: boolean
    overwritePreparedByImportData: boolean
    interfaceDetail: InterfaceDetail
    ruleClosingConditions: RuleBasedClosingCondition[]
    // Map<string, string[]> was the original type, as it most closely aligned with the dictionary it is saved as 
    // however due to various issues with using that type most of the components weren't actually using the type, 
    // they were using what the api response got transformed into, Array<[string, string[]]>
    // because this is transformed and recreated I've left it as any 
    closingConditions: any
    useParentRuleConditions: boolean
    useSystemDefaultSortOrder: boolean
    customSortGroupOrder: string[]
    loanLock: LoanLock
    regCheckSuiteOptions: RegCheckSuiteOptions
    regCheckEnabledWithinPropel: boolean
    products: Products
    audit: AuditInfo
}

export interface InterfaceDetail {
    automaticallyCreate4506TW9: boolean
    losIntegrationSettings: LosIntegrationSetting
    integrationLinks: string[]
}

export interface LoanLock {
    enable: boolean
    timeoutMinutes: number
    automaticallyLockLoans: boolean
}

export interface LosIntegrationSetting {
    xmlReturnResponse: string
    linkReturnResponse: string
    duplicateLoanAction: string
}

export enum ReturnResponseType {
    LoanDocuments = 'LoanDocuments',
    OrderNumberOnly = 'OrderNumberOnly',
    LoanEditLink = 'LoanEditLink',
    GenericSuccess = 'GenericSuccess'
}

export enum LinkResponseType {
    PropelLoanLink = 'PropelLoanLink',
    EmbeddedLoanLink = 'EmbeddedLoanLink'
}

export enum DuplicateLoanActionType {
    NoOverwriteError = ' NoOverwriteError',
    NoOverwriteSuccess = 'NoOverwriteSuccess',
    Overwrite = 'Overwrite'
}