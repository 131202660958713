import { Buydown } from "./buydown-details";
import { LoanPurpose } from "./loan";

export interface Terms {
    amortizationType: string
    assumption: Assumption
    asmNoReleaseOfLiability: boolean
    balanceOfSubordinateLiens: number
    balloonTerm: number
    baseLoanAmount: number
    bonaFideDiscountPoints?: number
    buydown: Buydown
    commitmentExpirationDate: Date
    disclosedApr: number
    disclosedIndexRate: number
    estimatedLoanAmount: number | null
    initialApr: number
    interestOnlyTerm: number
    isBusinessPurpose: boolean
    isNycem: boolean
    isSimultaneousSecondLien: boolean
    lienPosition: string
    loanPurpose: string
    mortgageType: MortgageType
    noNewAdvanceMoneyWithSameCreditor: boolean
    noteRate: number
    noteTerm: number
    piRoundingType: PiRoundingType
    pointsToObtainUndiscountedRate: number
    previouslyDisclosedTotalFinanceCharge: number
    previouslyDisclosedTotalOfPayments: number
    printHomeEquityLoanOnLeAndCd: boolean
    programHasLoanPurpose: boolean
    programHasTransactionDetail: boolean
    refinanceType: RefinanceType | null
    refinancingWithSameCreditor: boolean
    repaymentType: string
    section184Rider: string | null
    sectionOfAct: string | null
    transactionDetail: string | null
    undiscountedRate: number
    vaTitleHeld: string
    meetsLocalVaVarianceForBuyerBrokerCharges: boolean
}

export interface Assumption {
    assumedLoanAmount: number
    limitations: string
    terms: string
    printRefinanceOnLeAndCd: boolean
}

export enum RefinanceType{
    LimitedCashOut = "LimitedCashOut",
    NoCashOut = "NoCashOut",
    CashOut = "CashOut"
}

export enum MortgageType {
    Conventional = "Conventional",
    FHA = "FHA",
    VA = "VA",
    HELOC = "HELOC",
    USDA = "USDA",
    LocalAgency = "LocalAgency",
    PublicAndIndianHousing = "PublicAndIndianHousing",
    StateAgency = "StateAgency",
    Other = "Other"
}

export const enum LienPosition{
    First = "First",
    Subordinate = "Subordinate"
}

export enum TransactionDetailType{
    ConstructionToPermanent = "ConstructionToPermanent",
    ConstructionOnly = "ConstructionOnly",
    OneTimeClose = "OneTimeClose",
    Homestyle = "Homestyle",
    ConventionalStreamline = "ConventionalStreamline",
    TXHomeEquity = "TXHomeEquity",
    TX50 = "TX50",
    HomeImprovement = "HomeImprovement",
    FHA203k = "FHA203k",
    Section184 = "Section184",
    FHAStreamline = "FHAStreamline",
    USDARehabilitation = "USDARehabilitation",
    VAAlteration = "VAAlteration",
    VAIRRRL= "VAIRRRL",
    BridgeLoan = "BridgeLoan"
}

export interface TransactionDetailFilter {
    mortageType?: MortgageType
    loanPurpose?: LoanPurpose
    lienPosition?: string
    state?: string
    transactionDetail: string[]
    matchesTransactionDetail?: boolean
}

export enum PiRoundingType {
    NormalRounding = 'Normal',
    RoundUp = 'Round Up',
    Truncate = 'Truncate'
}
