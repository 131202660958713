<template>
    <fg-select 
        :id="id"
        label="Applicable States"
        size="l"
        :showEmptyOption="!itemWithEmptyStateListAsFallbackExists"
        :source="availableStates"
        :multiple="true"
        v-model="localStates"
        :disabled="readOnly" />
</template>

<script setup lang="ts">
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { PropType, computed, defineProps, defineEmits, ref } from 'vue'
import { referenceService } from '@/common/services'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:"update:states", items: any[]) : void
}>()

const props = defineProps({
    id: { type: String, required: true },
    states: { type: Array as PropType<Array<string>>, required: true },
    usedStates: { type: Array as PropType<Array<string>>, required: true },
    itemWithEmptyStateListAsFallbackExists: { type: Boolean },
    readOnly: { type: Boolean }
})

const allStates = ref<MultiSelectItem[]>([])
//#endregion

//#region COMPUTED
const localStates = computed({
    get: () => props.states,
    set: (items: any[]) => emit('update:states', items)
})

// Get all states not used elsewhere. Include the or statement since usedStates includes all states used by all preparedBys including this one
const availableStates = computed(() => allStates.value.filter(s => !props.usedStates.includes(s.value) || localStates.value.includes(s.value)));
//#endregion

//#region INITIALIZE
initialize()

function initialize() {
    referenceService.getFullNameStates()
        .then((apiStates) => allStates.value = apiStates.map(s => ({ value: s.abbreviation, text: s.name } as MultiSelectItem)) || [])
}
//#endregion

</script>