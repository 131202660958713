import axios from 'axios'
import { config } from '@/config'

class VersionService {
    async checkIfNewVersion(): Promise<boolean> {
        try {
            const versionFileUrl = this.getVersionFileUrl()
            const response = await axios.get(versionFileUrl)
            const versionNumber = response.data

            return config.versionNumber !== versionNumber
        } catch (err: any) {
            console.error('error retrieving latest build number', err)
            return false
        }
    }

    private getVersionFileUrl(): string {
        const versionFileUrl = new URL(window.location.origin)
        const relativePath = process.env.VUE_APP_PUBLIC_STATIC_PATH + '/version.txt'
        
        // remove any duplicate '/' chars
        versionFileUrl.pathname = relativePath.split('/').filter(x => x).join('/')

        // bust cache
        versionFileUrl.search = Date.now().toString()

        return versionFileUrl.href
    }
}

export const versionService = new VersionService()