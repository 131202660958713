<template>
    <div :class="`form-group field ${size }`">
        <label :for="`phone-${id}`">Phone</label>
        <div class="input-group">
            <input-pattern-mask
                pattern="phone"
                :id="`phone-${id}`"
                v-model="syncedPhone"
                :jsonPath="jsonPath"
                jsonProperty="phone"
                :readOnly="readOnly"
            />
            <input-text
                class="extension"
                placeholder="ext"
                :id="`phoneExt-${id}`"
                v-model="syncedExt"
                :jsonPath="jsonPath"
                jsonProperty="phoneExt"
                :disabled="readOnly"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:'update:phone', value: string)
    (e:'update:ext', value: string)
}>()

const props = defineProps({
    id: { type: String, required: true },
    phone: { type: String, default: '' },
    ext: { type: String, default: '' },
    size: { type: String, default: "ms" },
    jsonPath: { type: String, default: '' },
    readOnly: { type: Boolean },
})
//#endregion

//#region COMPUTED
const syncedPhone = computed({
    get() {
        return props.phone
    },
    set(value: string) {
        emit("update:phone", value)
    }
})

const syncedExt = computed({
    get() {
        return props.ext
    },
    set(value: string) {
        emit('update:ext', value)
    }
})
//#endregion
</script>