<template>
    <div :id="`${id}`">
        <h1>Defaults</h1>
        <div class="field-section m">
            <fg-radio-button
                :options="xmlImportOptions"
                size="l"
                :id="`${id}-import-radio-buttons`"
                v-model="syncOverwriteImportData"
                :name="`${id}-import-radio-buttons`"
                label="When importing, use these defaults:"
            />
            <fg-checkbox
                :id="`${id}-use-system-defaults`"
                size="l"
                label="Start with system defaults"
                v-model="syncUseSystemDefaults"
                :postText="useSystemDefaultsPostText"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { OverwriteImportDataEnum } from '@/common/models'
import { defineProps, defineEmits, computed } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:'update:useSystemDefaults', value: boolean) : void
    (e:'update:overwriteImportData', value: boolean) : void
}>()

const props = defineProps({
    id: { type: String, required: true },
    useSystemDefaults: { type: Boolean, default: true },
    overwriteImportData: { type: Boolean, required: true },
    useSystemDefaultsPostText: { type: String, default: '' }
})

const xmlImportOptions: MultiSelectItem[] = [
    { value: false, text: OverwriteImportDataEnum.DoNotOverwrite },
    { value: true, text: OverwriteImportDataEnum.OverwriteData }
]
//#endregion

//#region COMPUTED
const syncUseSystemDefaults = computed({
    get: () => props.useSystemDefaults,
    set: (value: boolean) => emit("update:useSystemDefaults", value)
})

const syncOverwriteImportData = computed({
    get: () => props.overwriteImportData,
    set: (value: boolean) => emit('update:overwriteImportData', value)
})
//#endregion
</script>