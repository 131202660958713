import axios from "axios"
import JSON5 from 'json5'

class Config implements ConfigTypes {
    versionNumber = process.env.VUE_APP_VERSION
    api: ApiSettings
    auth: AuthSettings
    app: AppSettings
    ui: UiSettings

    private constructor(settings: ConfigTypes) {
        this.api = settings.api
        this.auth = settings.auth
        this.app = settings.app
        this.ui = settings.ui
    }

    static async initialize() {
        const settingsResponse = await axios.get(Config.getAppSettingsUrl())  
        //use JSON5 to allow for comments in the json file (for things such as swapping local/dev urls, adding context to features, etc.)
        const settingsData = settingsResponse.data
        let settings: ConfigTypes
        // if a comment is added to appsettings.json, axios will return a string, so we need to use json5 to parse, otherwise we can take the object as is
        if (typeof settingsData === 'string'){
            settings = JSON5.parse(settingsData) as ConfigTypes
        } else { 
            settings = settingsData
        }     
        return new Config(settings)
    }

    static getAppSettingsUrl(): string {
        const appsettingsUrl = new URL(window.location.origin)
        const relativePath = `${process.env.VUE_APP_PUBLIC_STATIC_PATH}/appsettings.json`
        
        // remove any duplicate '/' chars
        appsettingsUrl.pathname = relativePath.split('/').filter(x => x).join('/')
        // bust cache
        appsettingsUrl.search = Date.now().toString()

        return appsettingsUrl.href
    }
}

export const config = await Config.initialize()

interface ConfigTypes {
    auth : AuthSettings
    app: AppSettings
    ui: UiSettings
    api: ApiSettings
}

interface AuthSettings {
    loginUrl: string
    scope: string
    clientId: string
    redirectUri: string
}

interface ApiSettings {
    loans: ApiSetting
    auth: ApiSetting
    admindata: ApiSetting
    marketdata: ApiSetting
    metadata: ApiSetting
    users: ApiSetting
    docgen: ApiSetting
    orders: ApiSetting
    delivery: ApiSetting
    files: ApiSetting
    mcd: ApiSetting
    docuSign: ApiBaseSetting
}

interface AppSettings {
    regCheckOnly: boolean
    copyErrorMessageNumLines: number
    timeoutWarning: number
    pingInterval: number
    useInactivityTimer: boolean
    useVersionChecker: boolean
    minSearchableCount: number
    api: ApiBaseSetting
}

interface UiSettings {
    newTaxInfo: string[]
}

interface ApiSetting {
    publicUrl: string
}

interface ApiBaseSetting { 
    baseUrl: string
}